<template>
  <!-- NOTIFICATIONS -->
  <div @click="trackNotificationsOpen">
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <feather-icon icon="BellIcon" class="text-large cursor-pointer mt-1 sm:mr-6 mr-2 notification-badge" :badge="unreadNotification > 0 ? unreadNotification : ''"/>

      <vs-dropdown-menu id="notifications-menu" class="notification-dropdown dropdown-custom vx-navbar-dropdown">

        <div class="notification-top text-center p-5 bg-primary text-white">
          <h3 class="text-white">{{unreadNotification}} {{$t('New')}}</h3>
          <p class="opacity-75">{{$t('App Notifications')}}</p>
        </div>

        <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
          <ul class="bordered-items" v-for="item in notifications" :key="item.index">
            <li @click="openNotification(item)" class="px-6 py-4 notification cursor-pointer">
              <div v-if="item.status === 'unread'" class="h-2 w-2 rounded-full mr-2 mt-2 bg-primary"></div>
              <div class="flex">
                <h5 class="font-large text-semibold block notification-title">{{ item && item.subject ? item.subject : 'N/A' }}</h5>
                <small v-if="item && item.created" class="text-right items-end ml-auto">{{ 24 > (new Date() - new Date(item.created)) / (1000 * 60 * 60) ? `${Math.floor((new Date() - new Date(item.created)) / (1000 * 60 * 60))}h` : `${Math.floor((new Date() - new Date(item.created)) / (1000 * 60 * 60) / 24)}d`}} 
                  <span v-if="!isExpired(item.created)" class="text-red font-normal">{{item && item.link && item.link !== null && item.link.includes('s3.amazonaws.com') ? $t('Expired') : ''}}</span>
                </small>
              </div>
              <span>{{ item && item.excerpt ? item.excerpt : 'N/A' }}</span> 
            </li>
          </ul>
          <scroll-loader :loader-method="loadAllNotifications" :loader-disable="disable">
            <div>Loading...</div>
          </scroll-loader>
        </component>

        <div class="
          checkout-footer
          fixed
          bottom-0
          rounded-b-lg
          text-primary
          w-full
          p-2
          font-semibold
          text-center
          border
          border-b-0
          border-l-0
          border-r-0
          border-solid
          d-theme-border-grey-light
          cursor-pointer">
          <span @click="viewNotifications()">View All Notifications</span>
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AXIOS               from '../../../../http/axios/index.js'
import common              from '@/assets/utils/common'
import audio from '../../../../assets/audios/notification-tone-swift-gesture.mp3'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      audio,
      disable: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      notifications: [],
      unreadNotification: 0,
      unread: [],
      offset: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    isExpired (date) {
      const expiredDate = new Date(date)
      expiredDate.setHours(expiredDate.getHours() + 1)
      return expiredDate > new Date()
    },
    trackNotificationsOpen () {
      const dropdown = document.getElementById('notifications-menu')
      if (this.$store.state.AppActiveUser.userRole === 'merchant' && dropdown && this.releaseVersion !== 'staging' && dropdown.classList && dropdown.classList !== null && dropdown.classList.value.includes('dropdownx-enter-active')) {
        if (this.$store.state.AppActiveUser.userRole === 'merchant') {
          const merchantData = common.checkMerchantInLocalStorage(this)
          const userMerchant = ''
          let user = ''
          merchantData.then(results => {
            user = results.merchantData
            this.$mixpanel.track('INAPP_NOTIFICATIONS', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
          })
        }
      }
    },
    openNotification (notification) {
      this.$vs.loading()
      this.$vs.loading()
      if (this.$store.state.AppActiveUser.userRole === 'merchant') {
        const merchantData = common.checkMerchantInLocalStorage(this)
        let userMerchant = ''
        let user = ''
        merchantData.then(results => {
          user = results.merchantData
          userMerchant = user.status
          if (notification.subject.includes('New Bank Deposit')) {
            this.$mixpanel.track('INAPP_BANK', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
          }
          switch (notification.subject) {
          case 'Orders Upload Completed with Errors':
            this.$mixpanel.track('INAPP_UPLOAD_ERROR', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
            break
          case 'Orders Upload Successfully Completed':
            this.$mixpanel.track('INAPP_UPLOAD_SUCCESS', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
            break
          case 'Your export is complete!':
            this.$mixpanel.track('INAPP_EXPORT', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
            break
          case 'A new statement is available':
            this.$mixpanel.track('INAPP_STATEMENT', {
              merchant_name: user.name,
              merchant_email: userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
            break 
          default:
            break
          }
        })

      }
      this.updateNotification(notification)
    },
    playNotificationSound () {
      new Audio(this.audio)
        .play()
        .catch(() => {})
    },
    updateNotification (notification) {
      this.$vs.loading()
      const notifications = []
      notifications.push({
        created: notification.created,
        status: 'read'
      })
      AXIOS.patch(`${process.env.VUE_APP_API_URL}/api/v2/buzzer/in-app/`, notifications, {headers: this.headers}, {
      })
        .then(() => {
          this.loadAllNotifications('notScroll')
          this.$vs.loading.close()
          this.redirctRouter(notification)
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        })
    },
    redirctRouter (notification) {
      if (notification.link !== null && (this.isExpired(notification.created) || !notification.link.includes('s3.amazonaws.com'))) {
        if (notification.link.startsWith('https') || notification.link.startsWith('http')) {
          window.open(notification.link, '_self')
        } else {
          this.$router.push({
            path: `/${this.$i18n.locale}${notification.link}`
          }).catch(() => {})
        }
      }
    },
    viewNotifications () {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-notifications`
      }).catch(() => {})
      document.getElementById('notifications-menu').setAttribute('hidden', true)
    },
    loadAllNotifications (type) {
      AXIOS.get(`${process.env.VUE_APP_API_URL}/api/v2/buzzer/in-app/?last_evaluated_key=${this.offset}&limit=15`, {headers: this.headers}, {
      })
        .then((response) => {
          if (type) {
            this.notifications = response.data.results
          } else {
            this.notifications = [...this.notifications, ...response.data.results]
          }
          this.unread = this.notifications.map(element => element.status === 'unread').filter(item => item === true)
          localStorage.setItem('notifications', this.unread.length)
          this.disable = response.data.next === null
          this.offset = response.data.next
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
        })
    }
  },
  created () {
    this.loadAllNotifications('notScroll')
    setInterval(function () {
      this.unreadNotification = localStorage.getItem('notifications')
    }.bind(this), 100)
    if (process.env.VUE_APP_RELEASE_VERSION && process.env.VUE_APP_RELEASE_VERSION !== 'staging') {
      setInterval(function () {
        const expiry = Number(localStorage.getItem('tokenExpiry'))
        if (Date.now() < expiry) {
          this.loadAllNotifications('notScroll')
        }
      }.bind(this), 60000)
    }
  }
}

</script>


<style lang="scss" scoped>
.unread {
  background: #edf1f1;
  font-weight: 600;
  border-left: 0px solid;
}
.notification-badge .feather-icon-badge {
  background: red !important;
  font-size: 18px;
}
</style>